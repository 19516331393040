import request from "../utils/request";

// 分页获取字典列表
export function queryDictPage(params) {
    return request({
        url: "/vc-dict",
        method: "GET",
        params
    })
}

// 添加字典
export function saveDict(params) {
    return request({
        url: "/vc-dict",
        method: "POST",
        data: params
    })
}

// 修改数据
export function editDictRequest(params) {
    return request({
        url: "/vc-dict",
        method: "PUT",
        data: params
    })
}

// 删除字典
export function delDict(id) {
    return request({
        url: "/vc-dict/" + id,
        method: "DELETE"
    })
}