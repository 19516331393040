<template>
  <div>
    <!--搜索-->
    <!-- <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" :model="queryInfo">
            <el-form-item class="text" label="用户姓名">
              <el-input
                  placeholder="请输入用户姓名"
                  v-model="queryInfo.name"
                  size="small"
                  clearable>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" size="mini" type="primary" @click="refresh()">搜索</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="onReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row> -->
    <!--工具栏-->
    <el-row>
      <el-col :span="24">
        <div class="tools">
          <el-button @click="handleAdd" size="mini" icon="el-icon-plus" type="primary">新增字典</el-button>
          <el-button :loading="false" :disabled="false" size="mini" icon="el-icon-refresh" @click="refresh()">刷新</el-button>
        </div>
      </el-col>
    </el-row>
    <!--表格-->
    <el-row>
      <el-col :span="24">
        <div style="width: auto;">
          <el-table
              :data="tableData"
              stripe
              style="width: 100%;">
            <el-table-column
                type="selection"
                align="center"
                width="110">
            </el-table-column>
            <el-table-column
                prop="id"
                label="字典id"
                width="250">
            </el-table-column>
            <el-table-column
                prop="name"
                label="字典名称"
                width="250">
            </el-table-column>
              <el-table-column
                      prop="code"
                      label="字典code值"
                      width="250">
              </el-table-column>
              <el-table-column
                      prop="value"
                      label="字典value值"
                      width="250">
              </el-table-column>
              <el-table-column
                      prop="createTime"
                      label="创建时间">
              </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="text"

                    icon="el-icon-delete"
                    @click="handleDelete(scope.$index, scope.row)">删除
                </el-button>

                <el-button
                    size="mini"
                    type="text"

                    icon="el-icon-edit"
                    @click="handleEdit(scope.$index, scope.row)">修改
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 15px;text-align: right;">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :page-sizes="[10,20]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog
        title="新增"
        :visible.sync="addDialogVisible"
        width="50%">
      <el-form :inline="true" :model="addDictInfo">
        <el-form-item class="text" label="字典名称">
          <el-input
              placeholder="名称"
              v-model="addDictInfo.name"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
        <el-form-item class="text" label="字典code值">
          <el-input
              placeholder="code值"
              v-model="addDictInfo.code"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
        <el-form-item class="text" label="字典value值">
          <el-input
              placeholder="value值"
              v-model="addDictInfo.value"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="addDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addDict()">确 定</el-button>
  </span>
    </el-dialog>

  <el-dialog
        title="编辑"
        :visible.sync="editDialogVisible"
        width="50%">
      <el-form :inline="true" :model="editDictInfo">
        <el-form-item class="text" label="字典名称(无修改不传即可)">
          <el-input
              placeholder="名称"
              v-model="editDictInfo.name"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
        <el-form-item class="text" label="字典code值(无修改不传即可)">
          <el-input
              placeholder="code值"
              v-model="editDictInfo.code"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
        <el-form-item class="text" label="字典value值(无修改不传即可)">
          <el-input
              placeholder="value值"
              v-model="editDictInfo.value"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="editDict()">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import { queryDictPage } from '@/api/dict.js'
import { saveDict } from '@/api/dict.js'
import { delDict } from '@/api/dict.js'
import { editDictRequest } from '@/api/dict.js'

export default {
  name: "Home",
  data() {
    return {
      addDictInfo: {
          name: "",
          code: "",
          value: ""
      },
      editDictInfo: {
        name: "",
        code: "",
        value: "",
        id: "",
        version: ""
      },
      tableData: [],
      queryInfo: {
        //当前的页数
        page: 1,
        //当前每页显示的多少条数据
        pageSize: 10
      },
      total: 0,
      addDialogVisible: false,
      editDialogVisible: false
    }
  },
  created () {
      this.refresh();
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    onReset() {
      this.refresh();
    },
    handleAdd(){
      this.addDialogVisible=true;
    },
    refresh() {
      queryDictPage(this.queryInfo).then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
          this.queryInfo.page = res.data.current
          this.queryInfo.pageSize = res.data.size
      })
    },
    addDict() {
        saveDict(this.addDictInfo).then((res) => {
            if (res.code === 0) {
              this.$message.success("添加成功")
                // 添加成功
                this.refresh();
            } else {
                // 添加失败
                this.$message.error(res.msg)
            }
            this.addDialogVisible = false
        })
    },
    editDict() {

       const params = {
         name: undefined,
         value: undefined,
         id: this.editDictInfo.id,
         version: this.editDictInfo.version,
         code: undefined
       }

        if (this.editDictInfo.name !== '') {
          params.name = this.editDictInfo.name
          this.editDictInfo.name = ""
        }
        if (this.editDictInfo.value !== '') {
          params.value = this.editDictInfo.value
          this.editDictInfo.code = ""
        }
        if (this.editDictInfo.code !== '') {
          params.code = this.editDictInfo.code
          this.editDictInfo.code = ""
        }

      editDictRequest(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("修改成功")
          this.refresh()
        } else {
          this.$message.error(res.msg)
        }
      })
      this.editDialogVisible=false;

    },
    handleEdit(index, row) {
      this.editDictInfo.id = row.id
      this.editDictInfo.version = row.version
      this.editDialogVisible=true;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('是否确认删除字典名称为"' + row.name + '"的数据项??', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        delDict(row.id).then((res) => {
          if (res.code === 0) {
            this.$message.success("删除成功!")
          } else {
            this.$message.error(res.msg)
          }
        })

        this.refresh();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.refresh();
    },
    handleCurrentChange(val) {
      this.queryInfo.page = val
      this.refresh();
    },
  }
}
</script>
<!--公共样式-->
<style scoped lang="scss">

</style>
